import React, { useEffect, useState, useRef } from "react";
import "../styles/LPleads.css";
import AcadmeyLead from "./AcadmeyLead.jsx";
import CoachLead from "./CoachLead.jsx";
import UserLead from "./UserLead.jsx";
import NewUserLead from "../newuser/NewUserLead.jsx"
import PlayerLead from "../player/PlayerLead.jsx";
import {
  cdnurl
} from "./../utils/Constants";
const LeadCards = ({
  object,
  onLeadAdded,
  itemName,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [coachMenu, setCoachMenu] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const [newUserMenu, setNewUserMenu] = useState(false);
  const [playerMenu, setPlayerMenu] = useState(false);
  const menuButtonRef = useRef(null);
  const menuRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});

  const formatDate = (isoDate) => {
    const options = {
      // year: "2-digit",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-US", options);
  };

  const openModal = (object) => {
    if (itemName === "academy") {
      setModalVisible(true);
      setSelectedObj(object?.id);
    }
    if (itemName === "coach") {
      setCoachMenu(true);
      setSelectedObj(object?.id);
    }
    if (itemName === "user") {
      setUserMenu(true);
      setSelectedObj(object);
    }
    if (itemName === "newuser") {
      setNewUserMenu(true);
      setSelectedObj(object);
    }
    if (itemName === "player") {
      setPlayerMenu(true);
      setSelectedObj(object?.id);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const closeCoachModal = () => {
    setCoachMenu(false);
  };
  const closeUserModal = () => {
    setUserMenu(false);
  };
  const closeNewUserModal = () => {
    setNewUserMenu(false);
  };
  const closePlayerModal = () => {
    setPlayerMenu(false);
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        isMenuOpen &&
        !menuButtonRef.current.contains(event.target) &&
        !menuRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isMenuOpen]);


  return (
    <>
      <div key={object?.id} className="user-card2">
        <div className="card-container">
          <div className="card-leftBox">
            <div className="user-details">
              <p className="heading" onClick={() => openModal(object)}>
                {object?.id} - {object?.name}
              </p>
            </div>
            <div className="lead-value">
            </div>
            <div className="contact-details">
              <div className="mail sportCap">
                <p>{itemName === "user" || itemName === "newuser" ? <span> {object?.type} - {object?.parent_id}</span> : object?.sport_name}</p>
              </div>
              <div className="mail">
                <p>{object?.phone}</p>
              </div>
              {(itemName === "academy" || itemName === "coach" || itemName === "player") && (
                <div className="mail sportCap">
                {object?.loc_id === 17500 ? (
                  <p>{object?.city}, {object?.state}</p>
                ) : (
                  <p>{object?.location_locality || object?.location_city}</p>
                )}
              </div>
              
              )}

              <div className="mail">
                {formatDate(object?.creation_date)}
              </div>
            </div>
          </div>
          <div className="DealCard-rightBox">
            {itemName === "coach" && (
              <div className="mail">
                <div className="bmp-image-preview2">
                  <a href={object?.profile_img === null
                    ? `${cdnurl}asset/images/logo.svg`
                    : `${cdnurl}coach/${object?.id}/${object?.profile_img}`}
                    target="_blank" rel="noopener noreferrer">
                    <img
                      src={object?.profile_img === null || object?.profile_img === ""
                        ? `${cdnurl}asset/images/logo.svg`
                        : `${cdnurl}coach/${object?.id}/${object?.profile_img}`}
                      alt="pofile"
                      className="bmp-preview-image"
                    />
                  </a>
                </div>
              </div>
            )}
            {itemName === "academy" && (
              <div className="mail">
                <div className="bmp-image-preview2">
                  <a href={object?.logo === null
                    ? `${cdnurl}asset/images/logo.svg`
                    : `${cdnurl}academy/${object?.id}/${object?.logo}`}
                    target="_blank" rel="noopener noreferrer">
                    <img
                      src={object?.logo === null
                        ? `${cdnurl}asset/images/logo.svg`
                        : `${cdnurl}academy/${object?.id}/${object?.logo}`}
                      alt="pofile"
                      className="bmp-preview-image"
                    />
                  </a>
                </div>
              </div>
            )}
            {itemName === "player" && (
              <div className="mail">
                <div className="bmp-image-preview2">
                  <a href={object?.logo === null
                    ? `${cdnurl}asset/images/logo.svg`
                    : `${cdnurl}player/${object?.id}/${object?.logo}`}
                    target="_blank" rel="noopener noreferrer">
                    <img
                      src={object?.logo === null
                        ? `${cdnurl}asset/images/logo.svg`
                        : `${cdnurl}player/${object?.id}/${object?.logo}`}
                      alt="pofile"
                      className="bmp-preview-image"
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {modalVisible && (
        <AcadmeyLead
          selectedItem={selectedObj}
          closeModal={closeModal}
          onLeadAdded={onLeadAdded}
        />
      )}
      {coachMenu && (
        <CoachLead
          selectedItem={selectedObj}
          closeModal={closeCoachModal}
        />
      )}
      {userMenu && (
        <UserLead
          selectedItem={selectedObj}
          closeModal={closeUserModal}
          onLeadAdded={onLeadAdded}
        />
      )}
      {newUserMenu && (
        <NewUserLead
          selectedItem={selectedObj}
          closeModal={closeNewUserModal}
          onLeadAdded={onLeadAdded}
        />
      )}
      {playerMenu && (
        <PlayerLead
          selectedItem={selectedObj}
          closeModal={closePlayerModal}
        />
      )}

    </>
  );
};

export default LeadCards;
