export const skills ={
    16: ["coaching techniques", "exercise physiology", "time management"],
    2: ["Head Coach", "Assistant Coach", "Associate Head Coach", "Shooting Coach", "Defensive Coach", "Offensive Coach", "Player Development Coach", "Strength and Conditioning Coach", "Video Coordinator", "Analytics Coordinator", "Skills Coach", "Team Manager Coach", "Graduate Assistant Coach", "Director of Basketball Operations", "Youth Basketball Coach", "High School Basketball Coach", "College Basketball Coach", "Professional Basketball Coach", "National Team Coach", "Scout Coach"],
    32: ["Ashtanga Yoga Teacher", "Vinyasa Yoga Instructor", "Iyengar Yoga Teacher", "Kundalini Yoga Instructor", "Bikram Yoga Teacher", "Yin Yoga Instructor", "Restorative Yoga Teacher", "Kids Yoga Teacher", "Therapeutic Yoga Instructor", "Power Yoga Instructor"],
    34: ["General Fitness Trainers", "Strength and Conditioning Coach", "Specialized Trainer", "Weight Loss Specialist", "Rehabilitation and Medical Exercise Specialist", "Bodybuilding and Physique Coach", "Senior Fitness Trainer", "Youth Trainer", "Pre/Postnatal Fitness Trainer", "Functional Fitness Trainer", "Online Personal Trainer"],
    // "aerobics": ["Traditional Aerobics Instructors", "Step Aerobics Instructor", "Dance Aerobics Instructor", "Water Aerobics Instructor", "Kickboxing Aerobics Instructor", "High-Intensity Interval Training (HIIT) Instructor", "Sport-Specific Aerobics Instructor", "Cycling Instructors (Spin Instructor)", "Body Combat Instructor", "Functional Fitness Instructor", "Senior Fitness Instructor", "Children's Fitness Instructor"],
    // "pilates": ["Mat Pilates Instructor", "Reformer Pilates Instructor", "Classical Pilates Instructor", "Contemporary Pilates Instructor", "Clinical Pilates Instructor", "Group Pilates Instructor", "Private Pilates Instructor", "Pilates for Special Populations Instructor", "Cadillac (Trapeze Table) Pilates Instructor", "Pilates Barre Instructor", "Integrated Pilates Instructor"],
    1: ["Head Coach", "Assistant Coach", "Offensive Coordinator", "Defensive Coordinator", "Special Teams Coach", "Position Coach", "Strength and Conditioning Coach", "Youth Football Coach", "High School Football Coach", "College Football Coach", "Professional Football Coach", "Video Analyst", "Player Development Coach", "Quality Control Coach"],
    3: ["Head Coach", "Assistant Coach", "Batting Coach", "Bowling Coach", "Fielding Coach", "Spin Bowling Coach", "Fast Bowling Coach", "Wicketkeeping Coach", "Fitness Trainer", "Physiotherapist", "Performance Analyst", "Mental Conditioning Coach", "High-Performance Coach", "Under-19 Coach", "Women's Team Coach", "Team Manager"],
    5: ["Learn-to-Swim Instructors", "Competitive Swimming Coach", "Diving Coach", "Synchronized Swimming Coach", "Open Water Swimming Coach", "Masters Swimming Coach", "Water Polo Coach", "Disability Swimming Coach", "Stroke Development Coach", "High School Swimming Coach", "College Swimming Coach", "Elite Swimming Coach", "Infant Swimming Instructors", "Lifeguard Trainers", "Triathlon Swimming Coach"],
    26: ["Sprint Coach", "Distance Coach", "Hurdles Coach", "Jumping Events Coach", "Throwing Events Coach", "Combined Events Coach", "Relay Coach", "Strength and Conditioning Coach", "Race Walking Coach", "Technical Coach", "Youth Athletics Coach", "High School Track and Field Coach", "Collegiate Track and Field Coach", "Professional Track and Field Coach", "Cross Country Coach", "Athletics Development Coach", "Sports Science Support Coach", "Rehabilitation Coach"],
    6: ["Head Coach", "Assistant Coach", "Technical Coach", "Tactical Coach", "Strength and Conditioning Coach", "Junior Development Coach", "High Performance Coach", "Singles Specialist Coach", "Doubles Specialist Coach", "Mental Conditioning Coach", "Strategy Analyst Coach", "Personal Coach"],
    10: ["Head Coach", "Assistant Coach", "Defensive Coach", "Offensive Coach", "Fitness Coach", "Skills Coach", "Strategy Coach", "Analyst Coach", "Youth Kabaddi Coach", "High Performance Coach"],
    22: ["Head Coach", "Assistant Coach", "Fitness Coach", "Defensive Coach", "Offensive Coach", "Strategy Coach", "Youth Kho Kho Coach", "Skills Development Coach"],
    // "fitness training": ["fitness instructions", "coaching techniques", "exercise physiology", "current fitness trends knowledge", "knowledge of kinesiology", "designing exercise programs", "exercise equipment operation", "fitness assessments", "time management", "communication skills"],
    31: ["Personal Training", "Power Yoga", "Cardio Equipment","Strength Training Equipment", "Juice Bar", "Steam And Sauna", "Stretching Area", "Music And Video Entertainment", "Bollywood", "Zumba Classes", "Locker And Shower Facility","Free Trial", "Group Classes", "Sunday Open", "Parking"],
  }
  